import React, { useRef, useEffect, useState } from 'react';

const VideoPlayer = ({ src, currentTime, onTimeUpdate, editedTranscript }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  // Handle seeking and syncing with current time
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      // Check if editedTranscript exists and contains valid segments
      if (editedTranscript && editedTranscript.length > 0) {
        const currentSegment = editedTranscript.find(
          segment => currentTime >= segment.start && currentTime < segment.end
        );
        if (currentSegment) {
          // Only seek if the video is not already at the correct time
          if (Math.abs(video.currentTime - currentSegment.start) > 0.1) {
            video.currentTime = currentSegment.start;
          }
        }
      } else {
        // Seek to the currentTime if no transcript segments
        if (Math.abs(video.currentTime - currentTime) > 0.1) {
          video.currentTime = currentTime;
        }
      }
    }
  }, [currentTime, editedTranscript]);

  // Handle play/pause and time checking
  useEffect(() => {
    const video = videoRef.current;
    let frameId;

    const checkTime = () => {
      if (video.paused || !isPlaying) return;

      if (editedTranscript && editedTranscript.length > 0) {
        const currentSegment = editedTranscript.find(
          segment => video.currentTime >= segment.start && video.currentTime < segment.end
        );

        // If outside any segment, seek to the next segment or pause
        if (!currentSegment) {
          const nextSegment = editedTranscript.find(segment => segment.start > video.currentTime);
          if (nextSegment) {
            video.currentTime = nextSegment.start;
          } else {
            setIsPlaying(false); // No more segments, pause playback
            video.pause();
          }
        }
      }

      onTimeUpdate(video.currentTime);
      frameId = requestAnimationFrame(checkTime);
    };

    // Play or pause the video based on `isPlaying`
    if (isPlaying) {
      video.play().catch(() => setIsPlaying(false)); // Handle play promise
      frameId = requestAnimationFrame(checkTime);
    } else {
      video.pause();
    }

    return () => cancelAnimationFrame(frameId);
  }, [isPlaying, editedTranscript, onTimeUpdate]);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <video
        ref={videoRef}
        src={src}
        onClick={togglePlay}
        style={{ cursor: 'pointer' }}
      />
      <button onClick={togglePlay}>
        {isPlaying ? 'Pause' : 'Play'}
      </button>
    </div>
  );
};

export default VideoPlayer;
