import React, { useState, useCallback, useEffect, useRef } from 'react';

const EditableTranscript = ({ originalTranscript, onUpdate, apiResponse, updateVideoPreview }) => {
  const [editableContent, setEditableContent] = useState([]);
  const editableRef = useRef(null);

  const processApiResponse = useCallback((response) => {
    if (!response) return;
    const words = response.split(' ').map(wordInfo => {
      const [word, start, end, speaker] = wordInfo.split('|');
      return {
        word,
        start: parseFloat(start),
        end: parseFloat(end),
        speaker
      };
    });
    setEditableContent(words);
    if (onUpdate) onUpdate(words);
  }, [onUpdate]);

  useEffect(() => {
    if (apiResponse) {
      processApiResponse(apiResponse);
    }
  }, [apiResponse, processApiResponse]);

  const handlePaste = useCallback((e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const pastedWords = pastedText.split(/\s+/).map(word => {
      const [text, start, end, speaker] = word.split('|');
      return { 
        word: text, 
        start: parseFloat(start), 
        end: parseFloat(end), 
        speaker 
      };
    });
    
    setEditableContent(prevContent => [...prevContent, ...pastedWords]);
    if (onUpdate) onUpdate(prevContent => [...prevContent, ...pastedWords]);
  }, [onUpdate]);

  const handleInput = useCallback(() => {
    if (editableRef.current) {
      const content = editableRef.current.innerText;
      const lines = content.split('\n');
      let words = [];
      let currentSpeaker = 'UNKNOWN';

      lines.forEach(line => {
        if (line.startsWith('Speaker ')) {
          currentSpeaker = line.split(':')[0].replace('Speaker ', '').trim();
        } else {
          const lineWords = line.split(/\s+/).filter(w => w);
          words = [...words, ...lineWords.map((word, index) => {
            const existingWord = editableContent.find(w => w.word === word);
            return existingWord || {
              word,
              start: words.length + index, // placeholder
              end: words.length + index + 1, // placeholder
              speaker: currentSpeaker
            };
          })];
        }
      });

      setEditableContent(words);
      if (onUpdate) onUpdate(words);
    }
  }, [editableContent, onUpdate]);

  const generateDownloadableJSON = useCallback(() => {
    const jsonContent = JSON.stringify({ words: editableContent }, null, 2);
    const blob = new Blob([jsonContent], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'edited_transcript.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [editableContent]);

  const handleUpdateVideoPreview = useCallback(() => {
    if (updateVideoPreview) {
      updateVideoPreview(editableContent);
    }
  }, [editableContent, updateVideoPreview]);

  const renderEditableContent = () => {
    let currentSpeaker = null;
    return editableContent.map((item, index) => {
      const speakerChanged = item.speaker !== currentSpeaker;
      currentSpeaker = item.speaker;
      return (
        <React.Fragment key={`${item.word}-${item.start}-${item.end}-${index}`}>
          {speakerChanged && (
            <>
              {index !== 0 && <br />}
              <strong>Speaker {item.speaker}: </strong>
              <br />
            </>
          )}
          {item.word}{' '}
        </React.Fragment>
      );
    });
  };

 return (
    <div>
      <div 
        ref={editableRef}
        contentEditable
        onPaste={handlePaste}
        onInput={handleInput}
        style={{ 
          minHeight: '100px', 
          border: '1px solid black', 
          padding: '10px', 
          whiteSpace: 'pre-wrap',
          lineHeight: '1.5'
        }}
      >
        {renderEditableContent()}
      </div>
      <button onClick={handleUpdateVideoPreview}>Update Video Preview</button>
      <button onClick={generateDownloadableJSON}>Download Edited Transcript JSON</button>
    </div>
  );
};

export default EditableTranscript;