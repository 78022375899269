import axios from 'axios';

const PROCESS_URL = process.env.REACT_APP_PROCESS_URL || 'http://74.235.95.232:8004';
const MAX_TOKENS = 4000; // Leave some buffer for the model's response

// Simple function to generate a unique ID
function generateUniqueId() {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

// Simple function to estimate token count (adjust as needed)
function estimateTokenCount(text) {
  return text.split(/\s+/).length;
}

// Function to create a condensed representation of the transcript
function condenseTranscript(transcript) {
  console.log('Condensing transcript...');
  return transcript.map(segment => ({
    id: generateUniqueId(),
    start: segment.segment.start,
    end: segment.segment.end,
    speaker: segment.segment.speaker,
    text: segment.segment.text
  }));
}

// Function to split the condensed transcript into chunks
function chunkTranscript(condensedTranscript, chunkSize = 500) {
  console.log('Chunking transcript...');
  const chunks = [];
  let currentChunk = [];
  let currentSize = 0;

  for (const segment of condensedTranscript) {
    if (currentSize + estimateTokenCount(segment.text) > chunkSize && currentChunk.length > 0) {
      chunks.push(currentChunk);
      currentChunk = [];
      currentSize = 0;
    }
    currentChunk.push(segment);
    currentSize += estimateTokenCount(segment.text);
  }

  if (currentChunk.length > 0) {
    chunks.push(currentChunk);
  }

  console.log(`Created ${chunks.length} chunks`);
  return chunks;
}

// Function to create a summary for each chunk
async function summarizeChunks(chunks, updateState) {
  console.log('Summarizing chunks...');
  const summaries = [];
  for (let i = 0; i < chunks.length; i++) {
    updateState(`Summarizing chunk ${i + 1} of ${chunks.length}...`);
    const chunk = chunks[i];
    const chunkText = chunk.map(segment => `[${segment.speaker}]: ${segment.text}`).join('\n');
    const summary = await sendToLLM(chunkText, 'summarize', '', 'summarize');
    summaries.push({ summary, chunkIds: chunk.map(segment => segment.id) });
  }
  return summaries;
}

// Function to identify relevant chunks based on LLM response
function identifyRelevantChunks(llmResponse, summaries) {
  console.log('Identifying relevant chunks...');
  return summaries
    .filter(summary => llmResponse.toLowerCase().includes(summary.summary.toLowerCase()))
    .flatMap(summary => summary.chunkIds);
}

// Function to get relevant segments from the original transcript
function getRelevantSegments(relevantChunkIds, condensedTranscript, fullTranscript) {
  console.log('Getting relevant segments...');
  const relevantSegments = condensedTranscript
    .filter(segment => relevantChunkIds.includes(segment.id))
    .map(segment => {
      const fullSegment = fullTranscript.find(s => 
        s.segment.start === segment.start && s.segment.end === segment.end
      );
      return fullSegment || segment;
    });
  return relevantSegments;
}

// Function to prepare the final input for the LLM
function prepareFinalLLMInput(relevantSegments, initialResponse, userInput) {
  console.log('Preparing final LLM input...');
  const segmentsText = relevantSegments
    .map(segment => `[${segment.segment.speaker}]: ${segment.segment.text}`)
    .join('\n');
  
  const finalInput = `
Initial analysis: ${initialResponse}

Relevant transcript segments:
${segmentsText}

User query: ${userInput}

Please provide a detailed response based on the above information.
  `.trim();

  // Ensure we're within token limit
  const tokenCount = estimateTokenCount(finalInput);
  if (tokenCount > MAX_TOKENS) {
    console.warn(`Final input exceeds token limit. Truncating...`);
    return finalInput.split(' ').slice(0, MAX_TOKENS).join(' ');
  }

  return finalInput;
}

// Function to send requests to the LLM
async function sendToLLM(text, promptTemplate, userInput, task) {
  console.log(`Sending request to LLM for task: ${task}`);
  try {
    const response = await axios.post(`${PROCESS_URL}/process_request`, {
      text: text,
      prompt_template: promptTemplate,
      user_input: userInput,
      task: task,
    });
    if (response.data && response.data.result) {
      console.log(`Received response from LLM for task: ${task}`);
      return response.data.result;
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    console.error('Error sending request to LLM:', error);
    throw error;
  }
}

// Main function to process video content
export const processVideoContent = async (wordTimingJson, promptTemplate, userInput, task, updateState) => {
  try {
    updateState('Condensing transcript...');
    const condensedTranscript = condenseTranscript(wordTimingJson.transcription);
    
    updateState('Chunking transcript...');
    const chunks = chunkTranscript(condensedTranscript);
    
    updateState('Summarizing chunks...');
    const summaries = await summarizeChunks(chunks, updateState);
    
    updateState('Performing initial analysis...');
    const combinedSummaries = summaries.map(s => s.summary).join('\n\n');
    const initialResponse = await sendToLLM(combinedSummaries, promptTemplate, userInput, 'initial_analysis');
    
    updateState('Identifying relevant chunks...');
    const relevantChunkIds = identifyRelevantChunks(initialResponse, summaries);
    
    updateState('Retrieving relevant segments...');
    const relevantSegments = getRelevantSegments(relevantChunkIds, condensedTranscript, wordTimingJson.transcription);
    
    updateState('Preparing final input...');
    const finalInput = prepareFinalLLMInput(relevantSegments, initialResponse, userInput);
    
    updateState('Generating final response...');
    const finalResponse = await sendToLLM(finalInput, promptTemplate, userInput, task);
    
    updateState('Processing complete.');
    return finalResponse;
  } catch (error) {
    console.error('Error processing video content:', error);
    updateState('Error occurred during processing.');
    if (error.response) {
      console.error('Server responded with error:', error.response.data);
      throw new Error(error.response.data.error || 'Server error');
    } else if (error.request) {
      console.error('No response received from server');
      throw new Error('No response from server');
    } else {
      console.error('Error setting up request:', error.message);
      throw error;
    }
  }
};