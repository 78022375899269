import React from 'react';

const TranscriptEditor = ({ transcript, currentTime, onWordClick }) => {
  const handleCopy = (e) => {
    e.preventDefault();
    const selection = window.getSelection();
    const selectedText = selection.toString();
    
    if (selectedText) {
      const container = e.target.closest('div');
      const spans = Array.from(container.getElementsByTagName('span'));
      
      const copiedWords = [];
      spans.forEach(span => {
        if (selection.containsNode(span, true)) {
          copiedWords.push(`${span.textContent.trim()}|${span.dataset.start}|${span.dataset.end}|${span.dataset.speaker}`);
        }
      });
      
      e.clipboardData.setData('text/plain', copiedWords.join(' '));
    }
  };

  let previousSpeaker = null;

  return (
    <div onCopy={handleCopy}>
      {transcript.flatMap((segment, segmentIndex) => {
        const speakerChanged = segment.segment.speaker !== previousSpeaker;
        previousSpeaker = segment.segment.speaker;

        return [
          speakerChanged && (
            <div key={`speaker-${segmentIndex}`} style={{ marginTop: '10px', marginBottom: '5px' }}>
              <strong>Speaker {segment.segment.speaker}</strong>
            </div>
          ),
          <div key={`segment-${segmentIndex}`} style={{ marginBottom: '10px' }}>
            {segment.words.map((word, wordIndex) => (
              <span
                key={`word-${segmentIndex}-${wordIndex}`}
                onClick={() => onWordClick(word.start)}
                data-start={word.start}
                data-end={word.end}
                data-speaker={segment.segment.speaker}
                style={{
                  backgroundColor: currentTime >= word.start && currentTime < word.end ? 'yellow' : 'transparent',
                  cursor: 'pointer'
                }}
              >
                {word.word}{' '}
              </span>
            ))}
          </div>
        ];
      })}
    </div>
  );
};

export default TranscriptEditor;