import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';

const EditedVideoPreview = ({ editedTranscript, originalVideo, onSeek }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const videoRef = useRef(null);

  const words = useMemo(() => editedTranscript || [], [editedTranscript]);

  const duration = useMemo(() => 
    words.reduce((total, word) => total + (word.end - word.start), 0),
  [words]);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current?.pause();
    } else {
      playFromCurrentWord();
    }
    setIsPlaying(!isPlaying);
  };

  const playFromCurrentWord = useCallback(() => {
    if (currentWordIndex < words.length && videoRef.current) {
      videoRef.current.currentTime = words[currentWordIndex].start;
      videoRef.current.play().then(() => setIsPlaying(true)).catch(error => console.error("Playback failed:", error));
    }
  }, [currentWordIndex, words]);

  const handleTimeUpdate = useCallback(() => {
    if (words.length === 0 || !videoRef.current) return;

    const videoTime = videoRef.current.currentTime;
    const currentWord = words[currentWordIndex];
    
    if (videoTime >= currentWord.end) {
      if (currentWordIndex < words.length - 1) {
        // Move to the next word
        const nextWord = words[currentWordIndex + 1];
        videoRef.current.currentTime = nextWord.start;
        setCurrentWordIndex(prevIndex => prevIndex + 1);
      } else {
        // End of playback
        videoRef.current.pause();
        setIsPlaying(false);
        setCurrentWordIndex(0);
      }
    }
  }, [words, currentWordIndex]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('timeupdate', handleTimeUpdate);
      return () => video.removeEventListener('timeupdate', handleTimeUpdate);
    }
  }, [handleTimeUpdate]);

  useEffect(() => {
    if (videoRef.current && words.length > 0) {
      videoRef.current.currentTime = words[0].start;
    }
  }, [words]);

  const handleWordClick = useCallback((index) => {
    setCurrentWordIndex(index);
    if (videoRef.current && words.length > index) {
      videoRef.current.currentTime = words[index].start;
      if (onSeek) {
        onSeek(words[index].start);
      }
      playFromCurrentWord();
    }
  }, [words, onSeek, playFromCurrentWord]);

  const getPlaybackTime = useCallback((index) => {
    return words.slice(0, index).reduce((total, word) => total + (word.end - word.start), 0);
  }, [words]);

  if (words.length === 0) {
    return <div>No edited transcript available.</div>;
  }

  return (
    <div>
      {originalVideo && (
        <video
          ref={videoRef}
          src={originalVideo}
          onEnded={() => {
            setIsPlaying(false);
            setCurrentWordIndex(0);
          }}
        />
      )}
      <button onClick={handlePlayPause} disabled={!originalVideo || words.length === 0}>
        {isPlaying ? 'Pause' : 'Play'}
      </button>
      <div>Current playback time: {getPlaybackTime(currentWordIndex).toFixed(2)}s / {duration.toFixed(2)}s</div>
      <div>
        {words.map((word, wordIndex) => (
          <span
            key={`word-${wordIndex}`}
            onClick={() => handleWordClick(wordIndex)}
            style={{
              cursor: 'pointer',
              backgroundColor: currentWordIndex === wordIndex ? 'lightyellow' : 'transparent',
              padding: '0 2px',
              margin: '0 2px',
              border: '1px solid #ccc',
              borderRadius: '3px',
              display: 'inline-block'
            }}
          >
            {wordIndex === 0 || word.speaker !== words[wordIndex - 1].speaker ? (
              <strong>Speaker {word.speaker}: </strong>
            ) : null}
            {word.word}
          </span>
        ))}
      </div>
    </div>
  );
};

export default EditedVideoPreview;