import React, { useState, useEffect, useRef } from 'react';
import VideoPlayer from './components/VideoPlayer';
import TranscriptEditor from './components/TranscriptEditor';
import Timeline from './components/Timeline';
import EditableTranscript from './components/EditableTranscript';
import EditedVideoPreview from './components/EditedVideoPreview';
import EditedTimelinePreview from './components/EditedTimelinePreview';
import { processVideoContent } from './components/Api';
import { promptTemplates } from './components/promptTemplates';
import './App.css';

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
  </div>
);

const ChatBot = ({ onSendMessage, messages }) => {
  const [input, setInput] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim() && selectedTemplate) {
      onSendMessage(input, selectedTemplate);
      setInput('');
    }
  };

  return (
    <div className="chatbot">
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.sender}`}>
            {msg.text}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <select 
          value={selectedTemplate} 
          onChange={(e) => setSelectedTemplate(e.target.value)}
        >
          <option value="">Select a template</option>
          {promptTemplates.map(template => (
            <option key={template.name} value={template.name}>
              {template.name}
            </option>
          ))}
        </select>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

const App = () => {
  const [videoSrc, setVideoSrc] = useState(null);
  const [originalTranscript, setOriginalTranscript] = useState([]);
  const [editedTranscript, setEditedTranscript] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [apiResponse, setApiResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [processingStage, setProcessingStage] = useState('');
  const videoRef = useRef(null);

  const updateVideoPreview = (updatedTranscript) => {
    setEditedTranscript(updatedTranscript);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setVideoSrc(url);
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    fetch(url)
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
      .then(buffer => setAudioBuffer(buffer))
      .catch(e => console.error('Error loading audio:', e));
  };

  const handleJsonUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const json = JSON.parse(e.target.result);
      setOriginalTranscript(json.transcription);
    };
    reader.readAsText(file);
  };

  const handleTimeUpdate = (time) => {
    setCurrentTime(time);
  };

  const handleWordClick = (time) => {
    setCurrentTime(time);
    if (videoRef.current) {
      videoRef.current.currentTime = time;
    }
  };

  const handleSeek = (time) => {
    setCurrentTime(time);
    if (videoRef.current) {
      videoRef.current.currentTime = time;
    }
  };

  const handleEditedTranscriptUpdate = (newEditedTranscript) => {
    setEditedTranscript(newEditedTranscript);
  };

  const handleWordSelect = (wordIndex) => {
    console.log('Current word index:', wordIndex);
  };

  const handleSendMessage = async (message, templateName) => {
    const newMessage = { text: message, sender: 'user' };
    setChatMessages(prev => [...prev, newMessage]);

    const selectedTemplate = promptTemplates.find(t => t.name === templateName);
    if (selectedTemplate) {
      setIsLoading(true);
      try {
        setProcessingStage('Chunking transcript...');
        const result = await processVideoContent(
          { transcription: originalTranscript },
          selectedTemplate.template,
          message,
          templateName,
          (stage) => setProcessingStage(stage)
        );
        setApiResponse(result);
        setChatMessages(prev => [...prev, { text: result, sender: 'bot' }]);
      } catch (error) {
        console.error('Error processing video content:', error);
        setChatMessages(prev => [...prev, { text: 'Error processing your request.', sender: 'bot' }]);
      } finally {
        setIsLoading(false);
        setProcessingStage('');
      }
    }
  };

  useEffect(() => {
    if (originalTranscript.length > 0) {
      const lastSegment = originalTranscript[originalTranscript.length - 1].segment;
      setDuration(lastSegment.end);
    }
  }, [originalTranscript]);

  return (
    <div className="app">
      <header className="app-header">
        <h1>Video Editor</h1>
        <div className="file-inputs">
          <input type="file" accept="video/*" onChange={handleFileUpload} />
          <input type="file" accept="application/json" onChange={handleJsonUpload} />
        </div>
      </header>
      {videoSrc && (
        <div className="editor-layout">
          <div className="main-panel">
            <div className="left-panel">
              <div className="video-container">
                <h2>Original Video</h2>
                <VideoPlayer
                  ref={videoRef}
                  src={videoSrc}
                  currentTime={currentTime}
                  onTimeUpdate={handleTimeUpdate}
                />
              </div>
              <div className="timeline-container">
                <Timeline
                  duration={duration}
                  currentTime={currentTime}
                  transcript={originalTranscript}
                  onSeek={handleSeek}
                  audioBuffer={audioBuffer}
                  onWordSelect={handleWordSelect}
                />
              </div>
            </div>
            <div className="right-panel">
              <div className="transcript-container">
                <h2>Original Transcript</h2>
                <TranscriptEditor
                  transcript={originalTranscript}
                  currentTime={currentTime}
                  onWordClick={handleWordClick}
                />
              </div>
              <div className="chatbot-container">
                <h2>ChatBot</h2>
                <ChatBot onSendMessage={handleSendMessage} messages={chatMessages} />
                {isLoading && (
                  <>
                    <LoadingSpinner />
                    <p>{processingStage}</p>
                  </>
                )}
              </div>
              <div className="editable-transcript-container">
                <h2>Editable Transcript</h2>
                <EditableTranscript
                  originalTranscript={originalTranscript}
                  onUpdate={handleEditedTranscriptUpdate}
                  apiResponse={apiResponse}
                  updateVideoPreview={updateVideoPreview}  
                />
              </div>
            </div>
          </div>
          {editedTranscript.length > 0 && (
            <div className="bottom-panel">
              <div className="edited-preview-container">
                <h2>Edited Video Preview</h2>
                <EditedVideoPreview
                  editedTranscript={editedTranscript}
                  originalVideo={videoSrc}
                  currentTime={currentTime}
                  onSeek={handleSeek}
                />
                <div className="edited-timeline-container">
                  <EditedTimelinePreview
                    duration={duration}
                    currentTime={currentTime}
                    editedTranscript={editedTranscript}
                    onSeek={handleSeek}
                    audioBuffer={audioBuffer}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default App;