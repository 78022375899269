export const promptTemplates = [
  {
    name: "Edit Transcript",
    template: `
Analyze the provided transcript and edit it according to the following user request:

{user_input}

Use the original transcript's word-level timing information to create a new structure that reflects the edited version. Follow these guidelines:

1. Maintain the original word-level timing and speaker information.
2. If entire segments are kept intact, preserve their original start and end times.
3. For rearranged or partially edited segments, adjust the start and end times of the affected words accordingly.
4. Ensure the edited transcript maintains a coherent flow and adheres to the user's request.
5. The output should be a string where each word is formatted as "word|start|end|speaker", with words separated by spaces.

Original Transcript JSON:
{input_json}

Please provide the edited transcript in the following format:
word1|start1|end1|speaker1 word2|start2|end2|speaker2 word3|start3|end3|speaker3 ...

Ensure that the edited transcript:
- Follows the user's instructions
- Maintains chronological order of the selected words
- Preserves the original timing information for each word
- Includes speaker information for each word

If any words or segments are removed, do not include them in the output. If the order of words or segments is changed, adjust their positions in the string accordingly.
    `
  }
];