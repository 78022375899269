import React, { useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';

const TimelineContainer = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
  background-color: #f0f0f0;
`;

const WaveformCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Playhead = styled.div`
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: red;
  z-index: 2;
`;

const Timeline = ({ duration, currentTime, transcript, onSeek, audioBuffer }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  const speakerColors = useMemo(() => {
    const speakers = new Set(transcript.map(segment => segment.segment.speaker));
    const colors = ['rgba(54, 162, 235, 0.5)', 'rgba(255, 99, 132, 0.5)', 'rgba(75, 192, 192, 0.5)'];
    return Array.from(speakers).reduce((acc, speaker, index) => {
      acc[speaker] = colors[index % colors.length];
      return acc;
    }, {});
  }, [transcript]);

  useEffect(() => {
    if (audioBuffer && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const width = canvas.width;
      const height = canvas.height;

      // Clear the canvas
      ctx.clearRect(0, 0, width, height);

      // Draw the waveform
      const data = audioBuffer.getChannelData(0);
      const step = Math.ceil(data.length / width);
      const amp = height / 2;

      ctx.beginPath();
      for (let i = 0; i < width; i++) {
        const min = Math.min(...data.slice(i * step, (i + 1) * step));
        const max = Math.max(...data.slice(i * step, (i + 1) * step));
        ctx.moveTo(i, (1 + min) * amp);
        ctx.lineTo(i, (1 + max) * amp);
      }
      ctx.stroke();

      // Draw segments
      transcript.forEach(segment => {
        const start = (segment.segment.start / duration) * width;
        const segmentWidth = ((segment.segment.end - segment.segment.start) / duration) * width;
        ctx.fillStyle = speakerColors[segment.segment.speaker];
        ctx.fillRect(start, 0, segmentWidth, height);
      });
    }
  }, [audioBuffer, transcript, duration, speakerColors]);

  const handleClick = (e) => {
    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const clickTime = (x / rect.width) * duration;
    onSeek(clickTime);
  };

  return (
    <TimelineContainer ref={containerRef} onClick={handleClick}>
      <WaveformCanvas ref={canvasRef} width={1000} height={100} />
      <Playhead style={{ left: `${(currentTime / duration) * 100}%` }} />
    </TimelineContainer>
  );
};

export default Timeline;